<template>
    <tr v-for="(item, i) in ledger.ledgers"  @click="openEditLine(keyName)" :key="i">
          <td :class="{'bg-danger-light' : !item.validation_date}">
            <span v-if="ledger.is_edit">
                 <input
                     type="date"
                     ref="date"
                     :item="`${i}`"
                     :value="item.date"
                     @change="onChangeDate"
                 >
            </span>
            <span v-else>
              {{ item.date }}
            </span>
          </td>
          <td>
            {{ item.voucher_type }}
          </td>
          <td>{{ keyName }}</td>
          <td :class="{'bg-danger-light' : !item.validation_account_head}">
            <span v-if="ledger.is_edit">
              <AsyncSelect
                  placeholder="Select Account Head"
                  v-model="item.account_head"
                  :api-service="fetchAccountHeadsList"
                  :reduce="name => name.name"
              />
            </span>
            <span v-else>
              {{ item.account_head }}
            </span>
           </td>
          <td>
            <span v-if="ledger.is_edit">
                <input v-if="item.debit != null" class="text-end" type="text" v-model="item.debit">
            </span>
            <span v-else>
              {{ item.debit }}
            </span>
          </td>
          <td>
            <span v-if="ledger.is_edit">
              <input v-if="item.credit != null" class="text-end" type="text" v-model="item.credit">
            </span>
            <span v-else> {{ item.credit }}</span>
          </td>
          <td>
            <span v-if="ledger.is_edit">
               <textarea v-model="item.note"></textarea>
            </span>
            <span v-else>{{ item.note }}</span>
          </td>
          <td>
            <span v-if="ledger.is_edit">
              <AsyncSelect
                  placeholder="Select Party"
                  v-model="item.party"
                  :api-service="fetchContactProfiles"
                  :format-label="formatPatientLabel"
                  :reduce="name => name.name"
              />
            </span>
            <span v-else>
              {{ item.party }}
            </span>
          </td>
          <td>
            <span v-if="ledger.is_edit">
             <v-select
                 v-model="item.business"
                 :options="businesses"
                 label="name"
                 :reduce="name => name.name"
             />
            </span>
            <span v-else>
              {{ item.business }}
            </span>
          </td>
          <td>
            <span v-if="ledger.is_edit">
              <v-select
                  v-model="item.cost_center"
                  :options="costCentres"
                  label="name"
                  :reduce="name => name.name"
              />
            </span>
            <span v-else>
              {{ item.cost_center }}
            </span>
          </td>
          <td>
            <span v-if="ledger.is_edit">
               <v-select
                   v-model="item.project"
                   :options="projects"
                   label="name"
                   :reduce="name => name.name"
               />
            </span>
            <span v-else>
              {{ item.project }}
            </span>
          </td>
          <td>
            <span v-if="ledger.is_edit">
             <v-select
                 v-model="item.budget_general"
                 :options="projectActivity"
                 label="name"
                 :reduce="name => name.name"
             />
            </span>
            <span v-else>
              {{ item.budget_general }}
            </span>
          </td>
          <td>
            <span v-if="ledger.is_edit">
               <v-select
                   v-model="item.fd6_account"
                   :options="ngoabHeads"
                   label="name"
                   :reduce="name => name.name"
               />
            </span>
            <span v-else>
              {{ item.fd6_account }}
            </span>
          </td>
          <td>
            <span v-if="ledger.is_edit">
              <v-select
                  v-model="item.program"
                  :options="programs"
                  label="name"
                  :reduce="name => name.name"
              />
            </span>
            <span v-else>
              {{ item.program }}
            </span>
          </td>
          <td>
            <span v-if="ledger.is_edit">
             <v-select
                 v-if="item.voucher_type == 'receipt_voucher' || item.voucher_type == 'payment_voucher' || item.voucher_type == 'contra_voucher'"
                 v-model="item.mop_account_head"
                 :options="cashAndBack"
                 label="name"
                 :reduce="name => name.name"
             />
            </span>
            <span v-else>
              {{ item.mop_account_head }}
            </span>
          </td>
      </tr>

     <tr>
          <td colspan="6">
              <div class="d-flex justify-content-between align-items-center mb-3">
                  <div>
                      <strong> Status: </strong>
                      {{ ledger.status }} |
                      <strong>Total Debit: </strong>
                      {{ totalDebit }} |
                      <strong>Total Credit: </strong>
                      {{ totalCredit }}
                   </div>
                <div>
                  <span v-if="ledger.is_edit">
                    <button @click="onclickUpdate" class="btn btn-sm btn-success">Update</button>
                  </span>
                  <button @click="onclickDelete" class="btn btn-sm btn-danger mx-2">
                    Delete
                  </button>
                </div>

              </div>
          </td>
          <td colspan="9">

          </td>
      </tr>
</template>

<script>
import handleContact from '@/services/modules/contact'
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import handleJournal from '@/services/modules/journal'

export default {
    name: 'LedgerRow',
    components: {AsyncSelect},
    emits:['onclickUpdate','onOpenEditItem','onclickDelete'],
    computed: {
        totalDebit () {
            let total = 0;
            this.ledger.ledgers.map(i => {
                if(i.debit) total += parseFloat(i.debit)
            })
            return total.toFixed(2)
        },
        totalCredit () {
            let total = 0;
            this.ledger.ledgers.map(i => {
                if(i.credit) total += parseFloat(i.credit)
            })
            return total.toFixed(2)
        },
    },
    methods: {
        onclickUpdate() {
            this.$emit('onclickUpdate', {ledger: this.ledger, keyName:this.keyName})
        },

        onclickDelete() {
          this.$emit('onclickDelete', {ledger: this.ledger, keyName:this.keyName})
        },

        onChangeDate(event) {
            let index = event.target.getAttribute("item");
            this.ledger.ledgers[index].date = event.target.value
        },

        openEditLine(keyName) {
           this.$emit('onOpenEditItem', keyName)
         }
    },
    setup() {
      const {fetchContactProfiles} = handleContact()
      const { formatPatientLabel } = useAsyncDropdownHelper();
      const {
        fetchAccountHeadsList,
      } = handleJournal()
      return {
        fetchContactProfiles,
        formatPatientLabel,
        fetchAccountHeadsList
      }
    },
    props: {
        ledger: Object,
        keyName: String,
        contactProfiles: Array,
        businesses: Array,
        costCentres: Array,
        projects: Array,
        ngoabHeads:Array,
        programs:Array,
        projectActivity:Array,
        cashAndBack:Array,
    }
}
</script>