<template>
    <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Voucher Type</th>
                        <th>Voucher No</th>
                        <th>Account Head</th>
                        <th>Debit</th>
                        <th>Credit</th>
                        <th>Line Description</th>
                        <th>Party</th>
                        <th>Business Unit</th>
                        <th>Cost Center</th>
                        <th>Project</th>
                        <th>Project Accounts Head</th>
                        <th>FD6 Account Head</th>
                        <th>Program Name</th>
                        <th>MoP Accounts Head</th>
                    </tr>
                </thead>
                <tbody ref="tableRef">
                    <LedgerRow 
                        v-for="(key, i) in ledgerKeys"
                        :key="i"
                        :ledger="ledgers[key]"
                        :keyName="key"
                        :contactProfiles="contactProfiles"
                        :businesses="businesses"
                        :costCentres="costCentres"
                        :projects="projects"
                        :ngoabHeads="ngoabHeads"
                        :programs="programs"
                        :projectActivity="projectActivity"
                        :cashAndBack="cashAndBack"
                        @onOpenEditItem="onOpenEditItem"
                        @onclickUpdate="onclickUpdate"
                        @onclickDelete="onclickDelete"
                     />
                </tbody>
            </table>
        </div>
</template>

<script>
import LedgerRow from '@/components/molecule/journal-import/LedgerRow.vue'
import { ref } from 'vue';
import useDetectOutsideClick from '@/services/useDetectOutsideClick';

export default {
    name: 'Ledger',
    emits:['onclickUpdate','onclickDelete'],
    components: {
        LedgerRow
    },
    computed: {
        ledgerKeys () {
            return this.ledgers ? Object.keys(this.ledgers) : []
        }
    },
    methods: {
        onclickUpdate(data) {
            this.$emit('onclickUpdate', data)
        },

        onclickDelete(data) {
            this.$emit('onclickDelete', data)
        },
         onOpenEditItem(keyName){
          this.resetItemList(keyName)
        },

    },
    setup(props) {
      const tableRef = ref(null)

      const resetItemList = (keyName = null) => {
        let ledgers = props.ledgers;
        Object.keys(ledgers).forEach((voucherKey) => {
          if (voucherKey === keyName) {
            ledgers[voucherKey].is_edit = true;
          }
          if (voucherKey !== keyName) {
            ledgers[voucherKey].is_edit = false;
          }
        });

      };

      useDetectOutsideClick(tableRef, resetItemList);

      return {
        tableRef,
        resetItemList
      };
    },
    props: {
        ledgers: Object,
        contactProfiles:Array,
        businesses:Array,
        costCentres:Array,
        projects:Array,
        ngoabHeads:Array,
        programs:Array,
        projectActivity:Array,
        cashAndBack:Array,
    }
}
</script>
